<template>
  <div class="anfang">
    <div class="anfang-body">
      <div
        class="item"
        v-for="(item, index) in listData"
        :key="index"
        @click="toDetail(item)"
      >
        <div class="item-t">
          <div class="name">监控名称：{{ item.name }}</div>
          <div class="status">{{ item.isdel == 0 ? "正常" : "异常" }}</div>
        </div>
        <div class="item-b">
          <span></span>
          <div class="look">查看</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCareraListUrl } from "./api";
export default {
  name: "anfang",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      requestData: {
        curPage: 1,
        pageSize: 20,
        activityCategory: 1,
      },
      listData: [],
      axiosHeader: {
        "Cockpit-Auth":
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjkyMjMzNzIwMzY4NTQ3NzU4MDcsInBob25lIjoiMTU5MTIzNDU2NzgiLCJ1c2VybmFtZSI6ImFkbWluX3lkIn0.K5cNEP6h76OBqHYSyYqHviXPJrPSNkbs2cxEgG-x0tA",
      },
    };
  },
  mounted() {
    this.getActivityList();
  },
  methods: {
    async getActivityList() {
      let params = {};
      let res = await this.$axios.get(`${getCareraListUrl}`, {
        headers: this.axiosHeader,
      });
      if (res.code == 0) {
        res.data.forEach((item) => {
          this.listData.push(item);
        });
      }
    },

    toDetail(item) {
      this.$router.push({
        name: "anfangDetail",
        query: { id: item.id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.anfang {
  .title {
    text-align: center;
    font-size: 30px;
    line-height: 80px;
  }
  .item {
    margin: 20px 0;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    padding: 20px;
    box-sizing: border-box;
    font-size: 30px;
    .item-t {
      display: flex;
      justify-content: space-between;
      .status {
        width: 150px;
        text-align: center;
      }
    }
    .item-b {
      display: flex;
      justify-content: space-between;
      margin: 20px 0 0;
    }
    .look {
      width: 150px;
      font-size: 30px;
      height: 60px;
      line-height: 60px;
      border-radius: 40px;
      text-align: center;
      background: #009aff;
      color: #fff;
    }
  }
}
</style>
